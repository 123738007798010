<template>
  <div class="medicine_container" :style="`background: ${info.bj_color}`">
    <div v-if="info.adv_img" class="img" @click="handleJump(info.adv_url)">
      <img :src="info.adv_img" alt="" />
    </div>
    <div class="content">
      <!-- <div v-for="item in list" :key="item.id" :id="item.id"> -->
      <!-- <div class="title">
          <img style="width: 42px; height: 25px" src="@/assets/img/zone/zuo.png" alt="" />
          <p>{{ item.title }}</p>
          <img style="width: 42px; height: 25px" src="@/assets/img/zone/you.png" alt="" />
          <div class="more" @click="handleMore(item)">更多 <i class="el-icon-arrow-right"></i></div>
        </div> -->
      <div class="item_wrap">
        <div class="item" v-for="sub in list" :key="sub.id">
          <item :item="sub" />
        </div>
      </div>
      <!-- </div> -->
    </div>

    <!--左侧导航  -->
    <!-- <floornav :list="list" /> -->
  </div>
</template>

<script>
import item from "./item.vue";
// import floornav from "@/components/floornav.vue";
import { handleJump, getZoneInfo } from "@/utils";

export default {
  components: {
    item,
    // floornav,
  },
  data() {
    return {
      handleJump,
      list: [],
      info: {},
    };
  },
  created() {
    getZoneInfo(this.$route.query.value).then((res) => {
      this.info = res;
    });
    this.$api("goods.getGoods", { tag: "zy" }).then((res) => {
      this.list = res.data.list;

      // this.list.forEach((e) => {
      //   e.id = "zyzq" + e.id;
      // });
    });
  },
  methods: {
    handleMore(item) {
      console.log("中药专区----", item);
      let firstTab = this.$store.state.pageName;
      console.log("面包屑---", firstTab);
      this.$store.commit("CHANGE_PAGENAME", [firstTab[0], item.title]);
      if (item.url.url == "/index/allGoods") {
        this.$store.commit("CHANGE_TAB", 1);
      }
      handleJump(item.url);
    },
  },
};
</script>

<style lang="less" scoped>
.medicine_container {
  background-size: cover;
  background: #f8f7f1;
  padding-bottom: 20px;
}
.img {
  width: 100%;
  height: 375px;
}
.title {
  width: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  color: #5a151a;
  position: relative;
  p {
    margin: 0 15px;
  }
  .more {
    position: absolute;
    right: 0;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
}
.item_wrap {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-right: 12px;
  }
  .item:nth-child(5n) {
    margin-right: 0;
  }
}
</style>
